import React from 'react'
import PropTypes from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Scrollspy from 'react-scrollspy'

import { Wrapper, Item, Indicator } from './styles.js'

const AsideNav = ({ active, data, activeItem, onClickFunc }) => {
  const onlyIds = data.map(item => item.url.substring(1))

  const handelClick = (to, index) => {
    scrollTo(to)
    onClickFunc && onClickFunc(to.substring(1))
  }

  return (
    <Wrapper active={active}>
      <div></div>
      <Scrollspy
        items={onlyIds}
        currentClassName="current"
        onUpdate={e => e && onClickFunc(e.id)}
      >
        {data.map((item, index) => (
          <Item
            key={item.id}
            active={item.url === `#${activeItem}`}
            onClick={() => handelClick(item.url, index)}
          >
            <p>
              <span>{item.name}</span>
            </p>
          </Item>
        ))}
      </Scrollspy>
      <Indicator activeItem={onlyIds.indexOf(activeItem)} />
    </Wrapper>
  )
}

AsideNav.propTypes = {
  active: PropTypes.bool,
  activeItem: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
}

AsideNav.defaultProps = {
  active: false,
  activeItem: '',
  data: [],
}

export default AsideNav
