import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Main from '@templates/Main'
import { useInView } from 'react-intersection-observer'

import AsideNav from '@atoms/AsideNav'
import HorWrap from '@atoms/HorWrap'
import OrangeHeader from '@atoms/OrangeHeader'

import HeroSection from '@organisms/HeroSection'
import Newsletter from '@organisms/Newsletter'
import PurpleSection from '@organisms/PurpleSection'
import Testimonials from '@organisms/Testimonials'
import VideoFull from '@organisms/VideoFull'
import Team from '@organisms/Team'
import WhatWeDo from '@organisms/WhatWeDo'
import HowWeWork from '@organisms/HowWeWork'
import Work from '@organisms/Work'

const IndexPage = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsHomePage
  const [activeSection, setActiveSection] = useState('#sec-one')
  const [refSec2, SecondInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [refSec3, VideoInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [refSec4, HowInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [refSec5, WorkInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [refSec6, PurpleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const [startPage, setStartPage] = useState(false)

  useEffect(() => {
    setTimeout(() => setStartPage(true), 100)
  }, [setStartPage])

  const navigation = [
    {
      id: '001',
      name: 'Hello',
      url: '#sec-one',
    },
    {
      id: '002',
      name: 'What we do',
      url: '#sec-two',
    },
    {
      id: '003',
      name: 'Who we are',
      url: '#sec-three',
    },
    {
      id: '004',
      name: 'How we work',
      url: '#sec-four',
    },
    {
      id: '005',
      name: 'What we create',
      url: '#sec-five',
    },
  ]

  return (
    <Main
      seo={pageData.seoMetaTags}
      transitionStatus={transitionStatus}
      enterColor="#ffffff"
    >
      <div id="sec-one">
        <HeroSection
          active={startPage && transitionStatus === 'entered'}
          video="773606157"
          mobileVideo="801046257"
          path="home"
          data={{
            header: pageData.heroHeader,
            line1: pageData.heroLine1,
            line2: pageData.heroLine2,
            button: pageData.heroButton[0].text,
            buttonLink: `/${pageData.heroButton[0].internalLink.slug}`,
          }}
        />
      </div>
      <div ref={refSec2} id="sec-two">
        <WhatWeDo
          active={SecondInView && transitionStatus === 'entered'}
          header={pageData.whatwedoHeader}
          description={pageData.whatwedoText}
        />
      </div>
      <div ref={refSec3} id="sec-three">
        <HorWrap>
          <OrangeHeader active={VideoInView} direction="left">
            03 Who we are
          </OrangeHeader>
        </HorWrap>
        <VideoFull active={VideoInView} video="783831849" />
        <Team
          active={VideoInView}
          header={pageData.whoweareHeader}
          description={pageData.whoweareText}
        />
      </div>
      <div ref={refSec4} id="sec-four">
        <HowWeWork active={HowInView && transitionStatus === 'entered'} />
      </div>
      <div ref={refSec5} id="sec-five">
        <Work active={WorkInView} />
      </div>
      <div ref={refSec6}>
        <PurpleSection active={PurpleInView} />
      </div>
      <Testimonials />
      <Newsletter />
      <AsideNav
        active={startPage}
        activeItem={activeSection}
        data={navigation}
        onClickFunc={setActiveSection}
      />
    </Main>
  )
}

export const query = graphql`
  query IndexPageQuery {
    datoCmsHomePage {
      seoMetaTags {
        tags
      }
      heroHeader
      heroLine1
      heroLine2
      heroButton {
        text
        internalLink {
          ... on DatoCmsWorkPage {
            slug
          }
        }
      }
      whatwedoHeader
      whatwedoText
      whoweareHeader
      whoweareText
    }
  }
`

export default IndexPage
