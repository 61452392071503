import styled from 'styled-components'

export const Wrapper = styled.div`
  z-index: 9000;
  position: fixed;
  top: 50%;
  right: 50px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  transform: translate3d(0, -50%, 0);
  transition: opacity 1s 4s ease;
  opacity: ${({ active }) => (active ? '1' : '0')};

  > div:nth-child(1) {
    content: '';
    z-index: 0;
    position: fixed;
    top: -100vh;
    right: -20vw;
    width: 120vw;
    height: 200vh;
    background: ${({ theme }) => theme.colors.black};
    transition: opacity 1s ease;
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    background: ${({ theme }) => theme.colors.grey3};
    opacity: 0.8;
    content: '';
    transition: height 1s 4.5s;
    height: ${({ active }) => (active ? '100%' : '0')};
  }

  > ul {
    width: 100%;
  }

  &:hover {
    &::before {
      width: 100%;
    }
    > div:nth-child(1) {
      opacity: 0.6;
    }
    li {
      opacity: 1;

      &:hover {
        p:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }

  ${({ theme }) => theme.media.m} {
    display: flex;
  }
`

export const Item = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  opacity: 0;
  transition: opacity 0.3s ease;
  will-change: opacity;

  &:last-child {
    height: 30px;
  }

  button,
  p {
    position: relative;
    display: inline-block;
    height: 30px;
    padding: 0 10px;
    text-align: right;
    white-space: nowrap;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    opacity: ${({ active }) => (active ? '1' : '0.5')};

    span {
      position: relative;
      line-height: 30px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: ${({ active }) => (active ? '100%' : '0')};
      height: 100%;
      background: ${({ theme }) => theme.colors.orange};
      transition: width 0.3s 0.3s ease;
    }
  }

  &:hover p {
    opacity: 1;
  }
`

export const Indicator = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  right: -2px;
  margin-left: -3px;
  width: 6px;
  height: 30px;
  background: ${({ theme }) => theme.colors.orange};
  transform: translate3d(0, ${({ activeItem }) => activeItem * 50}px, 0);
  transition: transform 0.3s 0.3s;
  will-change: transform:
`
